import React from 'react';
import Error from 'next/error';
import { NextPage } from 'next';
import { useIntl } from 'react-intl';
import Layout from '@components/Templates/Layout';

const FourOhFour: NextPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Error statusCode={404} title={intl.formatMessage({ id: 'description404' })} />
    </Layout>
  );
};

export default FourOhFour;
